export const GetRegions = (payload) => {
  return {
    type: "GET_REGIONS",
    payload: payload,
  };
};

export const errRegions = (payload) => {
  return {
    type: "ERR_REGIONS",
    payload: payload,
  };
};

export const regionsLoading = (payload) => {
  return {
    type: "REGIONS_LOADING",
    payload: payload,
  };
};
