export const addNotifications = (note) => {
  return (dispatch, getState) => {
    const length = getState().notifications.values.length;
    const values = {
      id: length,
      ...note,
    };
    dispatch({
      type: "ADD_NOTIFICATIONS",
      payload: values,
    });
  };
};

export const deleteNotifications = (note) => {
  return (dispatch, getState) => {
    //   const length = getState().notifications.values.length;
    const values = {
      // id: length,
      note,
    };
    dispatch({
      type: "DEL_NOTIFICATIONS",
      payload: values,
    });
  };
};
