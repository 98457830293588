import { history } from "../../../history";
import { SC } from "../../../components/@apis/helpers/serverCall";
import { module_ids } from "../../../components/@general/data";
import { sitesLoading, GetSites, errSites } from "../sites";
import { regionsLoading, GetRegions, errRegions } from "../regions";
import errorHandler from "../../../components/@apis/helpers/errorHandler";

export const setCurrentUser = (payload) => {
  return { type: "LOGIN_WITH_JWT", payload };
};

export const changeRole = (userRole) => {
  return { type: "CHANGE_ROLE", userRole };
};
export const setModules = (payload) => {
  return { type: "SET_MODULES", payload };
};

export const setVerifyOTP = (payload) => {
  return { type: "OTP_MESSAGE", payload };
};
export const setReqestMessage = (payload) => {
  return { type: "REQUEST_MESSAGE", payload };
};

export const setLoading = (payload) => {
  return { type: "REQUEST_LOADING", payload };
};

const getSites = (user) => {
  return async (dispatch) => {
    SC.getCall({
      url: "allSites",

      // customUrl: "https://admin.digitum.com.sa/api/allSites",
      // customToken:
      //   "614d821fbccf8a370b7a01a9|dyhZikmIr4eq6lvnAT1YZIAf2rkuHHh2nXO4vqE0",
    })
      .then((response) => {
        // console.log(" sites:", response?.data?.data, " user:", user);
        dispatch(
          GetSites({
            sites: response?.data?.data,
            user: user,
          })
        );
      })
      .catch((error) => {
        const err = errorHandler(error);
        dispatch(errSites(err));
      });
  };
};

const getRegions = (user) => {
  return async (dispatch) => {
    SC.getCall({ url: "region" })
      .then((response) => {
        dispatch(
          GetRegions({
            regions: response?.data?.data,
            user: user,
          })
        );
      })
      .catch((error) => {
        const err = errorHandler(error);
        dispatch(errRegions(err));
      });
  };
};

export const getUser = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    SC.postCall({ url: "token" }).then(
      (response) => {
        if (response.status === 200 && response.data) {
          // dispatch(setActiveSites({ user: response?.data?.data || {} }));

          // dispatch(sitesLoading(true));
          // dispatch(regionsLoading(true));
          dispatch(getSites(response?.data?.data || {}));
          dispatch(getRegions(response?.data?.data || {}));
          dispatch(setCurrentUser(response?.data?.data || {}));
          dispatch(changeRole(response?.data?.data?.type || ""));
          dispatch(setModules(response?.data?.data?.modules_id || []));
          dispatch(setLoading(false));
        }
      },
      (err) => {
        if (err) {
          history.push("/login");
        }
      }
    );
  };
};

export const login = (data) => {
  return async (dispatch) => {
    const token = data.data;
    localStorage.setItem("token", token);

    if (token) {
      SC.postCall({ url: "token" }).then(
        (response) => {
          dispatch(sitesLoading(true));
          dispatch(regionsLoading(true));
          dispatch(getSites(response?.data?.data || {}));
          dispatch(getRegions(response?.data?.data || {}));
          dispatch(setCurrentUser(response?.data?.data || {}));
          dispatch(changeRole(response?.data?.data?.type || ""));
          dispatch(setModules(response?.data?.data?.modules_id || []));
          let modules = response?.data?.data?.modules_id;
          if (modules?.length > 0) {
            switch (modules[0]) {
              case module_ids.EOD:
                return history.push("/eodform");
              case module_ids.Surveys:
                return history.push("/surveys");
              case module_ids.CheckIns:
                return history.push("/checkinDashboard");
              case module_ids.Supply_Chain:
                return history.push("/supplyChain");
              case module_ids.Site_Readiness:
                return history.push("/readinessList");
              case module_ids.IRS:
                return history.push("/incident/form");
              case module_ids.Vaccine_Sites:
                return history.push("/vaccineSites");

              default:
                return history.push("/");
            }
          } else {
            history.push("/");
          }
        },
        (err) => {
          if (err) {
            history.push("/login");
          }
        }
      );
    }
  };
};

export const verifyOTP = (data) => {
  return async (dispatch) => {
    dispatch(setVerifyOTP(data));
    history.push("/verify-user-identity");
  };
};

export const userRegisterRequet = (data) => {
  return async (dispatch) => {
    dispatch(setReqestMessage(data));
    history.push("/login");
  };
};

export const logout = ({ email }) => {
  return async (dispatch) => {
    localStorage.removeItem("token");
    dispatch(setCurrentUser({}));
    dispatch(changeRole(""));
    dispatch(setModules([]));
    const filters = {
      email,
    };
    if (email) SC.getCall({ url: "logout", params: filters });
    history.push("/login");
  };
};
