import React from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";
import classnames from "classnames";
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import { connect } from "react-redux";
import { logout } from "../../../redux/actions/auth/loginActions";
import {
  changeMode,
  changeDirection,
} from "../../../redux/actions/customizer/index";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import { Link } from "react-router-dom";
import { IntlContext } from "../../../utility/context/Internationalization";
import { FormattedMessage } from "react-intl";
import { SC } from "../../../components/@apis/helpers/serverCall";
import { deleteNotifications } from "../../../redux/actions/notifications";

const UserDropdown = (props) => {
  const handleLogout = () => {
    props.logout({ email: props?.user?.values?.email });
  };

  const handleMode = (e) => {
    props.changeMode(e);
  };
  let mode = props.app.customizer.theme;

  if (mode === "light") {
    mode = "dark";
  } else {
    mode = "light";
  }
  return (
    <DropdownMenu right>
      <DropdownItem>
        <Link to="/profile">
          <Icon.User size={14} className="mr-50" />
          <span className="align-middle">
            <FormattedMessage id="Edit Profile" defaultMessage="Edit Profile" />
          </span>
        </Link>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem tag="a" onClick={handleLogout}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">
          <FormattedMessage id="Log Out" defaultMessage="Log Out" />
        </span>
      </DropdownItem>
      {/* <UncontrolledDropdown tag="li" className="dropdown-theme-mode nav-item"> */}
      <DropdownItem tag="a" onClick={() => handleMode(mode)}>
        <Icon.Sun size={14} id="theme" className="mr-50" />
        <span className="align-middle text-capitalize">{mode} Mode</span>
      </DropdownItem>
      {/* </UncontrolledDropdown> */}
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    geolocationData: [],
    langDropdown: false,
  };

  componentDidMount() {
    axios
      .get(
        "https://geolocation-db.com/json/09068b10-55fe-11eb-8939-299a0c3ab5e5"
      )
      .then((data) => this.setState({ geolocationData: data }));
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  handleLangDropdown = () => {
    this.setState({ langDropdown: !this.state.langDropdown });
  };

  render() {
    let { notifications, vaccineSite, app } = this.props;
    let sortedNotifications = notifications.slice().sort((a, b) => b.id - a.id);

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <NavItem className="nav-location d-none d-sm-inline-block">
          {/* <NavLink className="nav-link">
            <ReactCountryFlag
              style={{ width: 20, height: 20 }}
              countryCode={
                this.state.geolocationData?.country_code?.toLowerCase()
                  ? this.state.geolocationData?.country_code?.toLowerCase()
                  : "sa"
              }
              svg
            />
            <span className="ml-1">
              <FormattedMessage id={vaccineSite} defaultMessage={vaccineSite} />
            </span>
          </NavLink> */}
        </NavItem>
        <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search">
            <Icon.Search size={20} data-tour="search" id="search" />
          </NavLink>
          <UncontrolledTooltip placement="bottom" target="search">
            Search
          </UncontrolledTooltip>
          <div
            className={classnames("search-input", {
              open: this.state.navbarSearch,
              "d-none": this.state.navbarSearch === false,
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary" />
            </div>
            <Autocomplete
              className="form-control"
              suggestions={this.state.suggestions}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped={true}
              placeholder="Explore Vaccination"
              autoFocus={true}
              clearInput={this.state.navbarSearch}
              externalClick={(e) => {
                this.setState({ navbarSearch: false });
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false,
                  });
                  this.props.handleAppOverlay("");
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                const IconTag = Icon[item.icon ? item.icon : "X"];
                return (
                  <li
                    className={classnames("suggestion-item", {
                      active: filteredData.indexOf(item) === activeSuggestion,
                    })}
                    key={i}
                    onClick={(e) => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div
                      className={classnames({
                        "d-flex justify-content-between align-items-center":
                          item.file || item.img,
                      })}
                    >
                      <div className="item-container d-flex">
                        {item.icon ? (
                          <IconTag size={15} />
                        ) : item.file ? (
                          <img
                            src={item.file}
                            height="36"
                            width="28"
                            alt={item.title}
                          />
                        ) : item.img ? (
                          <img
                            className="rounded-circle mt-25"
                            src={item.img}
                            height="28"
                            width="28"
                            alt={item.title}
                          />
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.title}</p>
                          {item.by || item.email ? (
                            <small className="text-muted">
                              {item.by
                                ? item.by
                                : item.email
                                ? item.email
                                : null}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className="meta-container">
                          <small className="text-muted">
                            {item.size
                              ? item.size
                              : item.date
                              ? item.date
                              : null}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                );
              }}
              onSuggestionsShown={(userInput) => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput);
                }
              }}
            />
            <div className="search-input-close">
              <Icon.X
                size={19}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    navbarSearch: false,
                  });
                  this.props.handleAppOverlay("");
                }}
              />
            </div>
          </div>
        </NavItem>
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={18} id="bell" />
            {notifications?.length > 0 ? (
              <Badge pill color="primary" className="badge-up">
                {notifications?.length}
              </Badge>
            ) : null}
            <UncontrolledTooltip placement="bottom" target="bell">
              Notifications
            </UncontrolledTooltip>
          </DropdownToggle>

          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">{notifications?.length} New</h3>
                <span className="notification-title">App Notifications</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false,
              }}
            >
              {sortedNotifications?.map((n) => (
                <div key={n.id} className="d-flex justify-content-between">
                  <Media className="d-flex align-items-start">
                    <Media left href="#">
                      <FeatherIcon
                        // className={`font-medium-5 ${n.nature}`}
                        icon={n.icon}
                        // fill="red"
                        // fill={n.nature}
                        // stroke={n.nature}
                        // color={n.nature}
                        stroke={
                          n.nature === "info"
                            ? "#00cfe8"
                            : n.nature === "primary"
                            ? "#28c76f"
                            : n.nature === "danger"
                            ? "#ea5455"
                            : "currentColor"
                        }
                        size={21}
                      />
                    </Media>
                    <Media body>
                      <Media
                        heading
                        className={`${n.nature} media-heading`}
                        tag="h6"
                      >
                        {n.title}
                      </Media>
                      <p className="notification-text">{n.description}</p>
                    </Media>
                    <small>
                      <time
                        className="media-meta"
                        dateTime={moment(n.time).fromNow()}
                      >
                        {moment(n.time).fromNow()}
                      </time>
                    </small>
                  </Media>
                </div>
              ))}
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span
                  className="align-middle"
                  onClick={() =>
                    // console.log("i am clicked")
                    // (notifications.length = 0)

                    this.props.deleteNotifications({})
                  }
                >
                  Read all notifications
                </span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>

        <IntlContext.Consumer>
          {(context) => {
            let langArr = {
              sa: "العربية",
              en: "English",
            };
            let setLocale = context.state.locale === "en" ? "sa" : "en";
            let setLang =
              context.state.locale === "en" ? langArr.sa : langArr.en;
            let setDirection =
              context.state.direction === "ltr" ? "rtl" : "ltr";

            return (
              <UncontrolledDropdown className="nav-item">
                <DropdownToggle
                  tag="a"
                  className="nav-link  mt-1"
                  onClick={() => {
                    changeDirection(setDirection);

                    context.switchLanguage(setLocale, setDirection);
                  }}
                >
                  {/* <IoLanguageOutline size={20} /> */}
                  <span className=" text-capitalize align-middle" id="language">
                    {setLang}
                  </span>
                  <UncontrolledTooltip placement="bottom" target="language">
                    {context.state.locale === "en"
                      ? "Switch to Arabic"
                      : "تبديل اللغة الإنجليزية"}
                  </UncontrolledTooltip>
                </DropdownToggle>
              </UncontrolledDropdown>
            );
          }}
        </IntlContext.Consumer>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status">
                <FormattedMessage id="Online" defaultMessage="Online" />
              </span>
            </div>
            <span data-tour="user">
              <Avatar
                className="mr-2"
                icon={<Icon.User id="user" />}
                status="online"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
        <UncontrolledTooltip placement="bottom" target="user">
          {this.props.userName}
        </UncontrolledTooltip>
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.customizer,
    notifications: state.notifications.values,
    user: state.auth.login,
  };
};
// const DropDown = connect(null, mapDispatchToProps)(UserDropdown);

export default connect(mapStateToProps, {
  logout,
  changeMode,
  changeDirection,
  deleteNotifications,
})(NavbarUser);
