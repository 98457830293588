const initialState = {
  values: [],
};
function Notifications(state = initialState, action) {
  switch (action.type) {
    case "ADD_NOTIFICATIONS":
      return {
        ...state,
        values: [...state.values, action.payload],
      };

    case "DEL_NOTIFICATIONS":
      return initialState;

    default:
      return state;
  }
}

export default Notifications;
