const initialState = {
  allRegions: [],
  activeRegions: [],
  activeRegionsCount: 0,
  regionsLoading: false,
  regionsError: "",
};

const nhccObj = [
  {
    IOC: "Riyadh",
    NHCC: "Riyadh First Health Cluster",
  },
  {
    IOC: "Jeddah",
    NHCC: "Jeddah Health Affairs",
  },
  {
    IOC: "Madinah",
    NHCC: "Madinah Health Cluster",
  },
  {
    IOC: "Al-Baha",
    NHCC: "Baha Health Affairs",
  },
  {
    IOC: "Assir",
    NHCC: "Asir Health Affairs",
  },
  {
    IOC: "Bisha",
    NHCC: "Bisha Health Affairs",
  },
  {
    IOC: "Eastern Province",
    NHCC: "Eastern Health Cluster",
  },
  {
    IOC: "Northern Boarder",
    NHCC: "Northern Borders Health Affairs",
  },
  {
    IOC: "Jizan",
    NHCC: "Jazan Health Affairs",
  },
  {
    IOC: "Hail",
    NHCC: "Hail Health Cluster",
  },
  {
    IOC: "Tabuk",
    NHCC: "Tabuk Health Affairs",
  },
  {
    IOC: "Qaseem",
    NHCC: "Qassim Health Cluster",
  },
  {
    IOC: "Najran",
    NHCC: "Najran Health Affairs",
  },
  {
    IOC: "Hafr Al Baten",
    NHCC: "Hafer AlBatin Health Affairs",
  },
  {
    IOC: "Makkah",
    NHCC: "Makkah Health Cluster",
  },
  {
    IOC: "Al-Qurayyat",
    NHCC: "Qurayat",
  },
  {
    IOC: "Taif",
    NHCC: "Taif Health Affairs",
  },
  {
    IOC: "Al-Jouf",
    NHCC: "Al-Jouf",
  },
  {
    IOC: "AlAhsa",
    NHCC: "Al Ahsa Health Cluster",
  },
  {
    IOC: "Al-Qunfudhah",
  },
  {
    IOC: "All Regions",
    NHCC: "All Regions/Clusters",
  },
];

function Regions(state = initialState, action) {
  switch (action.type) {
    case "GET_REGIONS":
      const { regions, user } = action.payload;
      let allRegions = regions;
      const regionWithALlRegionsName = {
        _id: "60d34b61dfe8cd6eb4588fd2",
        name: "All Regions",
      };
      allRegions = [...allRegions, regionWithALlRegionsName];
      //nhcc region na,e
      allRegions = allRegions.map((key) => {
        let nhccRegion = "";
        nhccRegion = nhccObj.filter((item) => item.IOC === key.name);
        if (nhccRegion.length > 0) {
          return { ...key, nhcc: nhccRegion[0].NHCC };
        } else {
          return { ...key };
        }
      });

      let activeRegions = allRegions.filter(
        (region) => region.name !== "All Regions"
      );
      if (user.type === "agent") {
        activeRegions = [];
      } else if (user.type === "region_head") {
        activeRegions = activeRegions.filter((region) =>
          user.region_ids.includes(region._id)
        );
      }
      const activeRegionsCount = activeRegions?.length || 0;
      return {
        ...state,
        allRegions: allRegions,
        activeRegions: activeRegions,
        activeRegionsCount: activeRegionsCount,
        regionsLoading: false,
      };

    case "ERR_REGIONS":
      return {
        ...state,
        regionsLoading: false,
        regionsError: action.payload,
      };

    case "REGIONS_LOADING":
      return {
        ...state,
        regionsLoading: action.payload,
      };

    default:
      return state;
  }
}

export default Regions;
