import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import patientFormReducer from "./sidebar";
import notifications from "./notifications";
import Sites from "./sites";
import Regions from "./regions";
import alert from "./alert";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  patientForm: patientFormReducer,
  notifications: notifications,
  sites: Sites,
  region: Regions,
  alert: alert,
});

export default rootReducer;
