export const GetSites = (payload) => {
  return {
    type: "GET_SITES",
    payload: payload,
  };
};
export const setActiveSites = (payload) => {
  return {
    type: "SET_ACTIVE_SITES",
    payload: payload,
  };
};
export const errSites = (payload) => {
  return {
    type: "ERR_SITES",
    payload: payload,
  };
};

export const sitesLoading = (payload) => {
  return {
    type: "SITES_LOADING",
    payload: payload,
  };
};
