import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { history } from "./history";
import * as serviceWorker from "./serviceWorker";
import { Layout } from "./utility/context/Layout";
import { store } from "./redux/storeConfig/store";
import { getUser, logout } from "./redux/actions/auth/loginActions";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import ErrorBoundary from "./components/@general/ErrorBoundary";
import { unProtectedRoutes } from "./UnprotectedRoutes";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/light.css";
import "./assets/scss/pages/data-list.scss";
import "./assets/scss/plugins/forms/flatpickr/flatpickr.scss";
import "./assets/scss/plugins/forms/react-select/_react-select.scss";
import "./index.scss";

require("dotenv").config();

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging"],
});
const BugSnagBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const LazyApp = lazy(() => import("./App"));

const token = localStorage.getItem("token");
const routes = history.location.pathname.split("/");
const unAuthorizedRoutes = unProtectedRoutes.filter((upRoutes) =>
  routes.includes(upRoutes)
)?.length;
if (token) {
  if (unAuthorizedRoutes === 0) {
    store.dispatch(getUser());
  }
} else {
  if (unAuthorizedRoutes === 0) {
    store.dispatch(logout({ email: "" }));
  }
}
ReactDOM.render(
  <BugSnagBoundary>
    <ErrorBoundary>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <Layout>
            <IntlProviderWrapper>
              {/* <ReduxApis /> */}
              <LazyApp />
              <ToastContainer />
            </IntlProviderWrapper>
          </Layout>
        </Suspense>
      </Provider>
    </ErrorBoundary>
  </BugSnagBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
