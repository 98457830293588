const initialState = {
  allSites: [],
  activeSites: [],
  sitesWithNvr: 0,
  sitesWithoutNvr: 0,
  sitesPercentageWithNvr: 0,
  allSitesCount: 0,
  activeSitesCount: 0,
  sitesLoading: false,
  sitesError: "",
};

function Sites(state = initialState, action) {
  switch (action.type) {
    case "GET_SITES":
      const { sites, user } = action.payload;
      const allSitesCount = sites?.length || 0;
      let activeSites = sites.filter((site) => site?.active);
      if (user.type === "agent") {
        activeSites = activeSites.filter(
          (site) => site._id === user.vaccine_site._id
        );
      } else if (user.type === "region_head") {
        activeSites = activeSites.filter((site) =>
          user.region_ids.includes(site.region_id)
        );
      }
      const activeSitesCount = activeSites?.length || 0;
      const sitesWithNvr =
        activeSites.filter((site) => site?.nvr_id)?.length || 0;
      return {
        ...state,
        sitesLoading: false,
        allSites: sites,
        activeSites: activeSites,
        sitesWithNvr: sitesWithNvr,
        sitesWithoutNvr: activeSitesCount - sitesWithNvr,
        sitesPercentageWithNvr: Math.round(
          (sitesWithNvr / activeSitesCount) * 100
        ),
        allSitesCount: allSitesCount,
        activeSitesCount: activeSitesCount,
      };
    case "SET_ACTIVE_SITES":
      const { user: loggedInUser } = action.payload;
      let stateActiveSites = state.allSites.filter((site) => site?.active);
      if (loggedInUser.type === "agent") {
        stateActiveSites = stateActiveSites.filter(
          (site) => site._id === loggedInUser.vaccine_site._id
        );
      } else if (loggedInUser.type === "region_head") {
        stateActiveSites = stateActiveSites.filter((site) =>
          loggedInUser.region_ids.includes(site.region_id)
        );
      }
      const stateActiveSitesCount = stateActiveSites?.length || 0;
      const stateSitesWithNvr =
        stateActiveSites.filter((site) => site?.nvr_id)?.length || 0;
      return {
        ...state,
        activeSites: stateActiveSites,
        sitesWithNvr: stateSitesWithNvr,
        sitesWithoutNvr: stateActiveSitesCount - stateSitesWithNvr,
        sitesPercentageWithNvr: Math.round(
          (stateSitesWithNvr / stateActiveSitesCount) * 100
        ),
        activeSitesCount: stateActiveSitesCount,
      };

    case "ERR_SITES":
      return {
        ...state,
        sitesLoading: false,
        sitesError: action.payload,
      };

    case "SITES_LOADING":
      return {
        ...state,
        sitesLoading: action.payload,
      };
    default:
      return state;
  }
}

export default Sites;
