import React from "react";
import * as Icon from "react-feather";
import { BiMinus } from "react-icons/bi";
import { FaRegCalendarCheck } from "react-icons/fa";
import { GiChaingun, GiStethoscope } from "react-icons/gi";
import { GoLocation } from "react-icons/go";
import { MdDashboard, MdDeviceHub } from "react-icons/md";
import { CgStack, CgDanger, CgFolderRemove } from "react-icons/cg";
import { TiChartPieOutline } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import {
  RiStockLine,
  RiSyringeLine,
  RiTaskLine,
  RiUserStarLine,
} from "react-icons/ri";

import { module_ids } from "../components/@general/data";

const navigationConfig = [
  {
    id: "mainDashboard",
    title: "Dashboard",
    type: "item",
    icon: <AiOutlineHome size={20} />,
    permissions: ["admin", "agent", "region_head"],
    modules: [
      module_ids.EOD,
      module_ids.CheckIns,
      module_ids.Supply_Chain,
      module_ids.ticketing_System,
      module_ids.HOS,
      module_ids.SEHA,
      module_ids.NVR,
      module_ids.Admin_Portal,
      module_ids.NVR_Account_Managers,
      module_ids.NVR_Data_Entry_Users,
      module_ids.Admin_Portal_Super_User,
      module_ids.Dashboard_User,
      module_ids.Site_Readiness,
      module_ids.IRS,
      module_ids.Surveys,
      module_ids.Vaccine_Sites,
    ], // all
    navLink: "/",
  },
  {
    id: "EOD",
    title: "EOD",
    type: "collapse",
    icon: <RiSyringeLine size={20} />,
    permissions: ["admin", "agent", "region_head"],
    modules: [module_ids.EOD], //eod ,
    children: [
      {
        id: "eoddashboard",
        title: "Compliance Dashboard",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.EOD], //eod
        navLink: "/eodDashboard",
      },

      {
        id: "eodSupplyChaindashboard",
        title: "Supply-Chain",
        type: "collapse",
        icon: <GiChaingun size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.EOD], //supplyChain

        children: [
          {
            id: "DashboardSupplyChain",
            title: "Dashboard",
            tooltip: "Supply-Chain Dashboard",
            type: "item",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "agent", "region_head"],
            modules: [module_ids.EOD], //supplyChain
            navLink: "/supplyChain",
          },
          {
            id: "trialBalanceSupplyChain",
            title: "Trial Balance",
            tooltip: "Supply-Chain Trial Balance",
            type: "item",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "region_head"],
            modules: [module_ids.EOD], //supplyChain
            navLink: "/trialBalance",
          },
          {
            id: "heatMapSupplyChain",
            title: "Heat map",
            tooltip: "Sites heat map",
            type: "item",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "region_head"],
            modules: [module_ids.EOD], //supplyChain
            navLink: "/siteHeatMap",
          },
        ],
      },

      {
        id: "eodForm",
        title: "EOD Form",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.EOD], //eod
        navLink: "/eodform",
      },

      {
        id: "eodlist",
        title: "EOD Submissions",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.EOD], //eod
        navLink: "/eodSubmissions",
      },

      {
        id: "eodReport",
        title: "Report",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.EOD], //eod
        navLink: "/eodReport",
      },
      {
        id: "eodPhysicalInventory",
        title: "Physical Inventory",
        type: "collapse",
        icon: <RiStockLine size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.EOD], //eod

        children: [
          {
            id: "eodPhysicalInventory",
            title: "Inventory Form",
            type: "item",
            tooltip: "Physical Inventory Form",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "agent", "region_head"],
            modules: [module_ids.EOD], //eod
            navLink: "/inventory/form",
          },

          {
            id: "eodInventorytListing",
            title: "Inventory Reports",
            type: "item",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "agent", "region_head"],
            modules: [module_ids.EOD],
            navLink: "/inventory/report",
          },
        ],
      },
    ],
  },
  {
    id: "siteClosure",
    title: "Site Closure",
    type: "collapse",
    icon: <CgFolderRemove size={20} />,
    permissions: ["admin", "agent", "region_head"],
    modules: [
      module_ids.EOD,
      module_ids.CheckIns,
      module_ids.Supply_Chain,
      module_ids.ticketing_System,
      module_ids.HOS,
      module_ids.SEHA,
      module_ids.NVR,
      module_ids.Admin_Portal,
      module_ids.NVR_Account_Managers,
      module_ids.NVR_Data_Entry_Users,
      module_ids.Admin_Portal_Super_User,
      module_ids.Dashboard_User,
      module_ids.Site_Readiness,
      module_ids.IRS,
      module_ids.Surveys,
      module_ids.Vaccine_Sites,
    ], // all //checkIn
    children: [
      {
        id: "siteClosureform",
        title: "Form",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [
          module_ids.EOD,
          module_ids.CheckIns,
          module_ids.Supply_Chain,
          module_ids.ticketing_System,
          module_ids.HOS,
          module_ids.SEHA,
          module_ids.NVR,
          module_ids.Admin_Portal,
          module_ids.NVR_Account_Managers,
          module_ids.NVR_Data_Entry_Users,
          module_ids.Admin_Portal_Super_User,
          module_ids.Dashboard_User,
          module_ids.Site_Readiness,
          module_ids.IRS,
          module_ids.Surveys,
          module_ids.Vaccine_Sites,
        ], // all //checkIn
        navLink: "/siteClosure/form",
      },
      {
        id: "siteClosurelist",
        title: "Submissions",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [
          module_ids.EOD,
          module_ids.CheckIns,
          module_ids.Supply_Chain,
          module_ids.ticketing_System,
          module_ids.HOS,
          module_ids.SEHA,
          module_ids.NVR,
          module_ids.Admin_Portal,
          module_ids.NVR_Account_Managers,
          module_ids.NVR_Data_Entry_Users,
          module_ids.Admin_Portal_Super_User,
          module_ids.Dashboard_User,
          module_ids.Site_Readiness,
          module_ids.IRS,
          module_ids.Surveys,
          module_ids.Vaccine_Sites,
        ], // all //checkIn
        navLink: "/siteClosure",
      },
    ],
  },
  {
    id: "checkin",
    title: "Check-in",
    type: "collapse",
    icon: <FaRegCalendarCheck size={20} />,
    permissions: ["admin", "agent", "region_head"],
    modules: [module_ids.CheckIns], //checkIn
    children: [
      {
        id: "checkinDashboard",
        title: "Dashboard",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.CheckIns], //checkIn
        navLink: "/checkinDashboard",
      },
      {
        id: "checkInForm",
        title: "Check-in Form",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.CheckIns], //checkIn
        navLink: "/checkinform",
      },
      {
        id: "checkInlist",
        title: "Check-in Submissions",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.CheckIns], //checkIn
        navLink: "/checkinSubmissions",
      },
    ],
  },
  {
    id: "site",
    title: "Site readiness",
    type: "collapse",
    icon: <RiTaskLine size={20} />,
    permissions: ["admin"],
    modules: [module_ids.Site_Readiness], // Site readiness
    children: [
      {
        id: "readinessList",
        title: "Site list",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.Site_Readiness], // Site readiness
        navLink: "/readinessList",
      },
    ],
  },
  {
    id: "survey",
    title: "Audit",
    type: "collapse",
    icon: <CgStack size={20} />,
    permissions: ["admin", "agent", "region_head"],
    modules: [module_ids.Surveys], //surveys
    children: [
      {
        id: "surveyDashboard",
        title: "Dashboard",
        type: "item",
        icon: <MdDashboard size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.Surveys], //surveys
        navLink: "/surveys",
      },
      // {
      //   id: "kpiDashboard",
      //   title: "KPI'S",
      //   type: "item",
      //   icon: <TiChartPieOutline size={17} />,
      //   permissions: ["admin", "agent", "region_head"],
      //   modules: [module_ids.Surveys], //surveys
      //   navLink: "/survey/clinical/dashboard",
      // },
      {
        id: "clinical",
        title: "Clinical",
        type: "collapse",
        icon: <GiStethoscope size={17} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.Surveys], //surveys
        children: [
          {
            id: "clinicalSurvey",
            title: "Audit",
            type: "item",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "agent", "region_head"],
            modules: [module_ids.Surveys], //surveys
            navLink: "/survey/clinical/audit",
          },

          {
            id: "clinicalSubmitted",
            title: "Submitted",
            type: "item",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "agent", "region_head"],
            modules: [module_ids.Surveys], //surveys
            navLink: "/survey/clinical",
          },
        ],
      },
      // {
      //   id: "digital",
      //   title: "Digital",
      //   type: "collapse",
      //   icon: <GiElectric size={17} />,
      //   permissions: ["admin", "agent", "region_head"],
      //   modules: [module_ids.Surveys], //surveys
      //   children: [
      //     {
      //       id: "digitalSurvey",
      //       title: "Audit",
      //       type: "item",
      //       icon: <BiMinus size={15} />,
      //       permissions: ["admin", "agent", "region_head"],
      //       modules: [module_ids.Surveys], //surveys
      //       navLink: "/survey/digital/audit",
      //     },
      //     {
      //       id: "DigitalSubmitted",
      //       title: "Submitted",
      //       type: "item",
      //       icon: <BiMinus size={15} />,
      //       permissions: ["admin", "agent", "region_head"],
      //       modules: [module_ids.Surveys], //surveys
      //       navLink: "/survey/digital",
      //     },
      //   ],
      // },
      // {
      //   id: "operational",
      //   title: "Operational",
      //   type: "collapse",
      //   icon: <Icon.Settings size={17} />,
      //   permissions: ["admin", "agent", "region_head"],
      //   modules: [module_ids.Surveys], //surveys
      //   children: [
      //     {
      //       id: "operationalSurvey",
      //       title: "Audit",
      //       type: "item",
      //       icon: <BiMinus size={15} />,
      //       permissions: ["admin", "agent", "region_head"],
      //       modules: [module_ids.Surveys], //surveys
      //       navLink: "/survey/operational/audit",
      //     },
      //     {
      //       id: "operationalSubmitted",
      //       title: "Submitted",
      //       type: "item",
      //       icon: <BiMinus size={15} />,
      //       permissions: ["admin", "agent", "region_head"],
      //       modules: [module_ids.Surveys], //surveys
      //       navLink: "/survey/operational",
      //     },
      //   ],
      // },
      {
        id: "supplyChainAudit",
        title: "Supply Chain",
        type: "collapse",
        icon: <MdDeviceHub size={17} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.Surveys], //surveys
        children: [
          {
            id: "supplyChainAuditSurvey",
            title: "Audit",
            type: "item",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "agent", "region_head"],
            modules: [module_ids.Surveys], //surveys
            navLink: "/survey/supplyChain/audit",
          },
          {
            id: "supplyChainAuditSubmitted",
            title: "Submitted",
            type: "item",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "agent", "region_head"],
            modules: [module_ids.Surveys], //surveys
            navLink: "/survey/supplyChain",
          },
        ],
      },
      {
        id: "customerExperience",
        title: "Customer Experience",
        type: "collapse",
        icon: <RiUserStarLine size={17} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.Surveys], //surveys
        children: [
          {
            id: "customerExperienceAudit",
            title: "Audit",
            type: "item",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "agent", "region_head"],
            modules: [module_ids.Surveys], //surveys
            navLink: "/survey/customerExperience/audit",
          },
          {
            id: "customerExperienceSubmitted",
            title: "Submitted",
            type: "item",
            icon: <BiMinus size={15} />,
            permissions: ["admin", "agent", "region_head"],
            modules: [module_ids.Surveys], //surveys
            navLink: "/survey/customerExperience",
          },
        ],
      },
      // {
      //   id: "trainingSurvey",
      //   title: "Training",
      //   type: "collapse",
      //   icon: <GiSkills size={17} />,
      //   permissions: ["admin", "agent", "region_head"],
      //   modules: [module_ids.Surveys], //surveys
      //   children: [
      //     {
      //       id: "trainingAudit",
      //       title: "Audit",
      //       type: "item",
      //       icon: <BiMinus size={15} />,
      //       permissions: ["admin", "agent", "region_head"],
      //       modules: [module_ids.Surveys], //surveys
      //       navLink: "/survey/trainingSurvey/audit",
      //     },
      //     {
      //       id: "trainingSubmitted",
      //       title: "Submitted",
      //       type: "item",
      //       icon: <BiMinus size={15} />,
      //       permissions: ["admin", "agent", "region_head"],
      //       modules: [module_ids.Surveys], //surveys
      //       navLink: "/survey/trainingSurvey",
      //     },
      //   ],
      // },
      // {
      //   id: "infectionControlAudit",
      //   title: "Infection Control",
      //   type: "collapse",
      //   icon: <AiOutlineFileProtect size={17} />,
      //   permissions: ["admin", "agent", "region_head"],
      //   modules: [module_ids.Surveys], //surveys
      //   children: [
      //     {
      //       id: "infectionControlAuditSurvey",
      //       title: "Audit",
      //       type: "item",
      //       icon: <BiMinus size={15} />,
      //       permissions: ["admin", "agent", "region_head"],
      //       modules: [module_ids.Surveys], //surveys
      //       navLink: "/survey/infectionControl/audit",
      //     },
      //     {
      //       id: "infectionControlAuditSubmitted",
      //       title: "Submitted",
      //       type: "item",
      //       icon: <BiMinus size={15} />,
      //       permissions: ["admin", "agent", "region_head"],
      //       modules: [module_ids.Surveys], //surveys
      //       navLink: "/survey/infectionControl",
      //     },
      //   ],
      // },
    ],
  },
  {
    id: "IRS",
    title: "Incident Reporting",
    type: "collapse",
    icon: <CgDanger size={20} />,
    permissions: ["admin", "agent", "region_head"],
    modules: [module_ids.IRS], // IRS
    children: [
      {
        id: "IRSForm",
        title: "Form",
        tooltip: "IRS Form",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.IRS], // IRS
        navLink: "/incident/form",
      },
      {
        id: "IRSList",
        title: "Reports",
        tooltip: "IRS Reports",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.IRS], // IRS
        navLink: "/incident/reports",
      },
    ],
  },
  {
    id: "VaccineSite",
    title: "V-Sites",
    tooltip: "Vaccine Sites",
    type: "collapse",
    icon: <GoLocation size={20} />,
    permissions: ["admin", "agent", "region_head"],
    modules: [module_ids.Vaccine_Sites], // Vaccine sites
    children: [
      {
        id: "VaccineSiteList",
        title: "List",
        tooltip: "List",
        type: "item",
        icon: <BiMinus size={15} />,
        permissions: ["admin", "agent", "region_head"],
        modules: [module_ids.Vaccine_Sites], // Vaccine sites
        navLink: "/vaccineSites",
      },
    ],
  },
  // {
  //   id: "eodBarCode",
  //   title: "beta (test)",
  //   tooltip: "EOD barCode (testing)",
  //   type: "collapse",
  //   icon: <Icon.List size={20} />,
  //   permissions: ["admin", "agent", "region_head"],
  //   children: [
  //     {
  //       id: "eodBarCodeForm",
  //       title: "BarCode form",
  //       tooltip: "BarCode form",
  //       type: "item",
  //       icon: <BiMinus size={15} />,
  //       permissions: ["admin", "agent", "region_head"],
  //       navLink: "/eod/barcode",
  //     },
  //     {
  //       id: "eodBarCodeBetaSubmissions",
  //       title: "Beta Submissions",
  //       tooltip: "Beta Submissions",
  //       type: "item",
  //       icon: <BiMinus size={15} />,
  //       permissions: ["admin", "agent", "region_head"],
  //       navLink: "/eod/barcode/submissions",
  //     },
  //   ],
  // },
  // {
  //   id: "users",
  //   title: "Users Access",
  //   type: "collapse",
  //   icon: <FontAwesomeIcon icon={faCogs} />,
  //   permissions: ["admin", "agent", "region_head"],
  //   children: [
  //     {
  //       id: "request",
  //       title: "User Requests",
  //       type: "item",
  //       icon: <Icon.Edit size={15} />,
  //       permissions: ["admin", "region_head"],
  //       navLink: "/users/request",
  //     },
  // {
  //   id: "eodlist",
  //   title: "Add Users",
  //   type: "item",
  //   icon: <BiMinus size={15} />,
  //   permissions: ["admin", "agent", "region_head"],
  //   navLink: "/eodSubmissions",
  // },
  // ,
  // {
  //   id: "eodlist",
  //   title: "List Users",
  //   type: "item",
  //   icon: <BiMinus size={15} />,
  //   permissions: ["admin", "agent", "region_head"],
  //   navLink: "/eodSubmissions",
  // },
  // ],
  // },
  // {
  //   id: "barcode",
  //   title: "Barcode",
  //   type: "collapse",
  //   icon: <FontAwesomeIcon icon={faBarcode} />,
  //   permissions: ["admin"],
  //   children: [
  //     {
  //       id: "barcodeform",
  //       title: "Barcode Form",
  //       type: "item",
  //       icon: <Icon.Edit size={15} />,
  //       permissions: ["admin"],
  //       navLink: "/barcodeform",
  //     },
  //   ],
  // },
  {
    type: "groupHeader",
    groupTitle: "Quick Links",
  },
  {
    id: "NHCC",
    title: "NHCC",
    type: "external-link",
    newTab: true,
    icon: <Icon.Link size={15} />,
    permissions: ["admin", "agent", "region_head"],
    // modules: [module_ids.ticketing_System],
    navLink: "https://nhcc.digitum.com.sa/login",
  },
  {
    id: "seha",
    title: "SEHA",
    type: "external-link",
    newTab: true,
    icon: <Icon.Link size={15} />,
    permissions: ["admin", "agent", "region_head"],
    // modules: [module_ids.SEHA],
    navLink: "https://www.seha.sa/",
  },
];

export default navigationConfig;
