const initialState = {
  userRole: "",
  values: {
    _id: "",
    name: "",
    email: "",
    type: "",
    phone: "",
    updated_at: "",
    created_at: "",
    authToken: 0,
    authToken_at: "",
    pmo: false,
    site_id: null,
    vaccine_site: null,
    regions: [],
    isPasswordUpdated: false,
  },
  modules_id: [],
  // userRole: "",
  // values: {},
  message: "",
  requestMessage: "",
  requestLoading: false,
};
export const login = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload };
    }
    case "LOGIN_WITH_JWT": {
      return { ...state, values: action.payload };
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload };
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload };
    }
    case "OTP_MESSAGE": {
      return { ...state, message: action.payload };
    }
    case "REQUEST_MESSAGE": {
      return { ...state, requestMessage: action.payload };
    }
    case "REQUEST_LOADING": {
      return { ...state, requestLoading: action.payload };
    }
    case "CHANGE_ROLE": {
      return {
        ...state,
        userRole: action.userRole,
      };
    }
    case "SET_MODULES": {
      return {
        ...state,
        modules_id: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
