export const SectorOptions = [
  {
    value: "MOH",
    label: "MOH",
  },
  {
    value: "NON-MOH",
    label: "NON-MOH",
  },
  {
    value: "Private",
    label: "Private",
  },
  {
    value: "all",
    label: "All",
  },
];
export const vaccineTypeOptions = [
  {
    value: "Astra-Zeneca",
    label: "Astra-Zeneca",
  },
  {
    value: "Pfizer",
    label: "Pfizer",
  },
  {
    value: "Moderna",
    label: "Moderna",
  },
  {
    value: "Janssen",
    label: "Janssen",
  },
  {
    value: "Novax",
    label: "Novax",
  },
  {
    value: "PfizerPediatric",
    label: "Pfizer – pediatric (5-11)",
  },
];

export const selectionInitialOptions = [{ value: "", label: "" }];

export const alertInitialValues = {
  show: false,
  message: "",
  color: "",
};

export const discrepanciesPackagesOptions = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

export const vaccineDisposalOptions = [
  {
    value: "Damage of vaccine during delivery تالف اثناء النقل للمركز",
    label: "Damage of vaccine during delivery تالف اثناء النقل للمركز",
  },
  {
    value:
      "Expired in the storage units انتهاء صلاحية اللقاح داخل وحدات التخزين",
    label:
      "Expired in the storage units انتهاء صلاحية اللقاح داخل وحدات التخزين",
  },
  {
    value:
      "Expired after preparation انتهاء صلاحية اللقاح قبل اعطائه للمستفيدينن",
    label:
      "Expired after preparation انتهاء صلاحية اللقاح قبل اعطائه للمستفيدينن",
  },
  {
    value: "Wrong dilution (Pfizer) تحضير خاطيء (لقاح فايزر)",
    label: "Wrong dilution (Pfizer) تحضير خاطيء (لقاح فايزر)",
  },
  {
    value:
      "Dilution of extra doses than required (Pfizer) تخفيف جرعات زائدة (فايزر)",
    label:
      "Dilution of extra doses than required (Pfizer) تخفيف جرعات زائدة (فايزر)",
  },
];
export const numericSelection = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
];
export const module_ids = {
  // Module IDS
  EOD: "602fdde7a6be8e60f144d7ea",
  CheckIns: "60314e9f4fc840701d1c8b34",
  Supply_Chain: "60477ad357d8e42a6b2d55e8",
  ticketing_System: "6048bf999cbba41b4d4e8fe0",
  HOS: "6048bf9f336e6421896ea86b",
  SEHA: "6048bfbb374caf056b558f70",
  NVR: "6048bfc19cbba41b4d4e8fe1",
  Admin_Portal: "604b84a21a116c0ebd741709",
  NVR_Account_Managers: "604e29f430d2c654f40a83ca",
  NVR_Data_Entry_Users: "6050d0853ab05b39c6211b13",
  Admin_Portal_Super_User: "6050d0a1825a7b48673c5598",
  Dashboard_User: "6050d0b1825a7b48673c55d2",
  Site_Readiness: "610bf64114a6591b02070e57",
  IRS: "610bf6531967614c3033d844",
  Surveys: "610bf67414a6591b02070e58",
  Vaccine_Sites: "610bf69d4cbd794b27135d66",
  siteClosure: "610bf69d4cbd794b27135d66",

  // Site_Readiness: "610a4e5a7310c13b7a0c4d93",
  // IRS: "610a4e6b7310c13b7a0c4d94",
  // Surveys: "610a4e7d7310c13b7a0c4d95",
  // Vaccine_Sites: "610a4e997310c13b7a0c4d96",
};
